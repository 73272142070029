<template>
    <div class="box">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover">
        <nav>
            <van-icon name="arrow-left" @click="onClickLeft" />
            <div class="nav_title">
                优惠券
            </div>
        </nav>

        <ul class="login_way">
            <li :class="type == 1 ? 'active' : ''" @click="changeType(1)">
                已启用
            </li>
            <div></div>
            <li :class="type == 2 ? 'active' : ''" @click="changeType(2)">
                未激活
            </li>
            <li :class="type == 3 ? 'active' : ''" @click="changeType(3)">
                已使用/过期
            </li>
        </ul>
        <div class="body">
            <div class="couponList" v-for="(item,index) in couponList">
                <div class="couponInfo">
                    <p class="couponTitle">{{item.coupon_name}}</p>
                    <div class="couponTag">
                        <p :class="type == 1 ? '':type == 2?'tagMouth':''">限月套餐</p>
                        <p :class="type == 1 ? '':type == 2?'tagOne':''">单次券</p>

                    </div>
                    <p class="couponTime" v-if="type == 1"> {{item.effect_time}} 激活</p>
                    <p class="couponTime" v-if="type == 2">受邀于{{item.addtime}}</p>
                    <p class="couponTime" v-if="type == 3"> {{item.use_time}}折扣</p>
                    <p class="couponHint" v-if="(item.u_nickname == null)"></p>
                    <p class="couponHint" v-else>该券源自邀请-{{item.u_nickname}}/{{item.u_phone}}</p>
                </div>
                <div :class="type == 1 ? 'couponStatus':type == 2?'couponStatus1':'couponStatus2'">
                    <div class="couponPrice">
                        <span class="couponPriceSymbol">￥</span>
                        {{item.value}}
                        <div class="lock" v-if="type == 2">
                            <img src="~@/assets/images/coupon/lock.png" alt="">
                            <p>锁定中未激活</p>
                        </div>
                    </div>
                    <div class="isUse" @click="UseCoupon()">{{type==3?'已使用':'去使用'}}
                        <van-icon v-if="type != 3" name="arrow" />
                    </div>
                </div>
                <div class="circle"></div>
            </div>
        </div>
        <van-empty v-if="couponList.length <= 0" description="暂无优惠券" />

    </div>
</template>
<script>
    import distubritionTwoApi from "@/untils/distubritionTwo.js";
    import Vue from 'vue';
    import { Empty } from 'vant';
    Vue.use(Empty);
export default{
    data(){
        return{
            type: 1,//tab选项卡
            couponList:[],//优惠券列表
            iccid:''//去使用优惠券参数
        }
    },
    mounted(){
        this.getCouponsList(this.type)
    },
    methods:{
        // 优惠券去使用
        UseCoupon(){
            if(this.type = 1){
                this.$router.push({
                path:'/index',
                query:{
                    dn:this.iccid
                }
            })
            }
        },
        // 返回
        onClickLeft(){
           this.$router.go(-1)
        },
        // 改变tab选项卡
        changeType(item) {
            this.getCouponsList(item)
            this.type = item;
        },
        // 获取优惠券列表
        getCouponsList(item){
            let _this = this
            let data = {
                phone:sessionStorage.getItem('phone'),
                openid:sessionStorage.getItem('openid')||'',
                // openid:'oKlK3t2KijApJ-5O5nIZy9v-eEb0',
                state:item,
            }
            distubritionTwoApi.getCouponsList({data}).then((res)=>{
                if(res.data.code == 1){
                    _this.$toast(res.data.msg)
                    for(let i =0; i<res.data.list.length; i++){
                        res.data.list[i].effect_time = res.data.list[i].effect_time.substring(0,10)
                        res.data.list[i].addtime= res.data.list[i].addtime.substring(0,10)
                        res.data.list[i].use_time = res.data.list[i].use_time.substring(0,10)
                        res.data.list[i].value = res.data.list[i].value.substring(0,2)
                    }
                    _this.couponList = res.data.list
                    _this.iccid = res.data.info.iccid
                }else{
                    _this.$toast(res.data.msg)
                    _this.couponList = []
                }
                
            })
        },
    }
}
</script>
<style scoped lang="less">
    .box{
        nav{
                display: flex;
                align-items: center;
                background-color: #0C60EE;
                color: #fff;
                height: 44px;
                font-size: 16px;
                padding-left: 10px;

            }
        .body{
            padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
            padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
            .couponList{
                width: 95%;
                margin: 15px auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 0;
                position: relative;
                overflow: hidden;
                background-color: #fff;
                border-radius: 10px;
                .couponInfo{
                    margin-left: 20px;
                    .couponTitle{
                        font-size: 18px;
                        font-weight: 500;
                        color: #222222;
                    }
                    .couponTag{
                        display: flex;
                        color: #fff;
                        margin: 8px 0;
                        p:nth-child(1){
                            background-color: #4080EC;
                            padding: 4px 5px;
                            margin-right: 8px;
                            border-radius: 3px;
                        }
                        p:nth-child(2){
                            background-color: #F4A72E;
                            padding: 4px 5px;
                            border-radius: 3px;
                        }
                        .tagMouth{
                            background-color: #C8C8C8 !important;
                        }
                        .tagOne{
                            background-color: #DADADA !important;
                        }

                    }
                    .couponTime{
                        color: #ccc;
                        font-size: 13px;
                        margin-top: 9px;
                    }
                    .couponHint{
                        width: 89%;
                        white-space: nowrap;;
                        overflow: hidden;//文本超出隐藏
                        text-overflow: ellipsis;//文本超出省略号替代
                        color: #ccc;
                        font-size: 12px;
                        margin-top: 18px;
                    }
                }
                .couponStatus{
                    color: #518EF4 !important;
                    .couponPrice{
                        font-size: 54px;
                        margin-bottom: 10px;
                        font-family: 'bahn';
                        font-weight: bold;
                        margin-right: 15px;
                        .couponPriceSymbol{
                            font-size: 16px;
                            margin-right: -10px;
                        }
                        
                    }
                    .isUse{
                            font-size: 16px;
                        }
                }
                .couponStatus1{
                    color:rgba(81, 142, 244, .1);
                    position: relative;
                    .couponPrice{
                        font-size: 54px;
                        margin-bottom: 10px;
                        font-family: 'bahn';
                        font-weight: bold;
                        margin-right: 15px;

                        .lock{
                            position: absolute;
                            top: 0;
                            left: 0;
                            text-align: center;
                            img{
                                width: 21px;
                                height: 25px;
                            }
                            p{
                                font-size: 12px;
                                white-space: nowrap;
                                color: #518EF4;
                                font-weight: 400;
                            }
                        }
                        .couponPriceSymbol{
                            font-size: 16px;
                            margin-right: -10px;
                        }
                        
                    }
                    .isUse{
                            font-size: 16px;
                        }
                }
                .couponStatus2{
                    color: #C3C9D5 !important;
                    border: #C3C9D5;
                    .couponPrice{
                        font-size: 54px;
                        margin-bottom: 10px;
                        font-family: 'bahn';
                        font-weight: bold;
                        margin-right: 15px;
                        .couponPriceSymbol{
                            font-size: 16px;
                            margin-right: -10px;
                        }
                    }
                    .isUse{
                            font-size: 16px;
                            text-align: center;
                        }
                    
                }

                .circle{
                    position: absolute;
                    top: 50%;
                    left: -7.5px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    margin-top: -7.5px;
                    background-color: rgb(243, 245, 247);
                }
            }
        }
        .login_way {
            display: flex;
            justify-content: space-around;
            // justify-content: space-between;
            align-items: center;
            font-size: 14px;
            // width: 90%;
            padding: 0px 30px;
            // border: 1px solid #1e6fff;
            background-color: #ECF0F7;
            height: 44px;
            li{
                // border: 1px solid red;
                padding: 5px 10px;
                color: #AAB3C2;
            }
            .active {
                color: #1e6fff;
                background-color: #E1E9F7;
                border-radius: 20px;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
</style>